<template>
    <Menu
        as="nav"
        class="w-full lg:hidden"
        tabindex="0"
        :class="{ 'flex-col': open }"
    >
        <div class="flex justify-between">
            <div class="flex items-center">
                <LogoLink class="lg:hidden" />
            </div>
            <div class="-mr-4 flex w-full flex-1 justify-end">
                <MenuButton
                    class="flex h-16 w-12 items-center justify-center text-gray-800 hover:text-gray-900 focus:text-gray-900 focus:outline-none"
                    @click="open = !open"
                >
                    <span class="sr-only">Menu</span>
                    <Icon
                        :name="!open ? 'bars-solid' : 'close'"
                        :class="[!open ? 'text-[1.8rem]' : 'text-[1.4rem]']"
                    />
                </MenuButton>
            </div>
        </div>
        <FadeTransition>
            <div
                v-show="open"
                class="h-[100dvh] flex-1 overflow-y-auto bg-white focus:outline-none"
                :class="{ 'z-[999999]': open }"
                :style="'padding-bottom: ' + paddingBottom + 'px'"
            >
                <div class="flex gap-x-4 pt-8">
                    <NuxtLink
                        class="btn-secondary"
                        to="/resources/design/design-lab"
                        @click="open = false"
                    >
                        Design Lab
                    </NuxtLink>
                    <NuxtLink
                        class="btn-primary"
                        to="/get-quote"
                        @click="open = false"
                    >
                        Get a Quote
                    </NuxtLink>
                </div>
                <div v-for="blok in data" :id="blok.key" :key="blok.key">
                    <MenuItems static>
                        <div class="mr-2 pt-8 text-xl font-bold">
                            {{ blok.title }}
                        </div>
                        <MenuItem
                            v-for="group in blok.groups"
                            :key="group"
                            as="ul"
                        >
                            <MenuListItem
                                :group="group"
                                @link-click="open = false"
                            />
                        </MenuItem>
                    </MenuItems>
                </div>
            </div>
        </FadeTransition>
    </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import MenuListItem from '~/layouts/includes/menu/MenuListItem.vue';
import FadeTransition from '~/components/transitions/FadeTransition.vue';
import LogoLink from '~/layouts/includes/menu/LogoLink.vue';

defineProps({
    data: {
        type: Array,
        required: true,
    },
});

const open = ref(false);
const paddingBottom = ref(0);
const menuHeight = 64;
const topBarHeight = 51;
const additionalPaddingBottom = 30;

watch(
    () => open.value,
    (isOpen) => {
        if (isOpen) {
            document.documentElement.style.overflow = 'hidden';
            if (window.scrollY < topBarHeight) {
                paddingBottom.value = topBarHeight - window.scrollY;
            }
            paddingBottom.value += menuHeight + additionalPaddingBottom;
        } else {
            paddingBottom.value = 0;
            document.documentElement.style.overflow = '';
        }
    },
);
</script>
